<template>
    <k-object-selector
        :value="value"
        :label="label"
        @input="changed"
        :itemFunc="itemFunc"
        :suggestionsFunc="suggestionsFunc"
        objectName="card"
        objectNamePlural="cards"
    >
        <template slot="item" slot-scope="{item}">
            <v-list-item-content>
                <v-list-item-title>{{ item.card_info.number }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.id }}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </k-object-selector>
</template>

<script>
import api from '@/services/api';

export default {
  props: {
    value: {
      required: true,
      validator: () => true,
    },
    label: {
      type: String,
      required: true,
    },
    onlyAuthorized: Boolean,
  },
  methods: {
    changed(value) {
      this.$emit('input', value);
    },
    itemFunc(id) {
      return api.kernel.get(`/sca_cards/${id}`);
    },
    suggestionsFunc(search) {
      if (this.onlyAuthorized) {
        return api.kernel.get('/sca_cards?state=authorized').then((data) => data.data);
      }
      if (!search) {
        return api.kernel.get('/sca_cards').then((data) => data.data);
      }
      return Promise.all([
        api.kernel.get(`/sca_cards/${search}`).then((data) => [data], () => []),
      ]).then((datas) => [].concat(...datas));
    },
  },

};
</script>
